import React, {useState} from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image";
import Layout from "../components/layout";
import {Alert, Col, Form, Row} from "react-bootstrap";
import productTemplateStyle from "./productTemplate.module.css";
import SkuCard from "../components/Products/skuCard";
import formStyle from "../pages/form.module.css";
import ContactEmailAddress from "../components/contactEmailAddress";
import globalStyle from "../styles/global.module.css";
import env from "../config/env";

export default function Template({data}) {

    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter } = markdownRemark;
    const [sku, setSku] = useState(null);
    const [formState, setFormValues] = useState({
        quantity: 1,
        size: "",
        email: "",
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
    });
    const [showAlert, setShowAlert] = useState(false);
    const [formResult, setFormResult] = useState({});
    const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
    const [showSizeError, setShowSizeErrorAlert] = useState(false);
    const [showEmailError, setShowEmailErrorAlert] = useState(false);

    const resetFormAfterSubmit = () => {
        setFormValues({
            quantity: "1",
            size: "",
            email: "",
            firstName: "",
            lastName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
        });
    };

    const handleQuantityChange = (e) => {
        if (e.target.value !== "" && e.target.value < 1) {
            e.target.value = formState[e.target.name];
        }

        handleFormChange(e);
    };

    const handleFormChange = (e) => {
        setFormValues({
            ...formState,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = (e) => {
        let isValid = true;

        setShowSizeErrorAlert(false)
        setShowEmailErrorAlert(false)

        if(!formState.size) {
            isValid = false;
            setShowSizeErrorAlert(true)
        }

        if(!formState.email) {
            isValid = false;
            setShowEmailErrorAlert(true);
        }

        return isValid
    }

    const handleSizeSelection = (e) => {
        handleFormChange(e);
        data.skus.edges.map(item => {
            let productName = item.node.product.name.replace(' (+ $1.50 Sales Tax)','');
            if (productName === frontmatter.title && item.node.attributes.size === e.target.value) {
                setSku(item.node);
            }
        });

    };

    return (
        <Layout className="site-content"
                title={frontmatter.title}
                path={frontmatter.path}
                description={frontmatter.description}
                image={env.domain + frontmatter.photo.publicURL}
                structuredData={{
                    "@context": "https://schema.org",
                    "@type": "Product",
                    "name": frontmatter.title,
                    "image": env.domain + frontmatter.photo.publicURL,
                    "brand": {
                        "name": "Mom's on Maternity"
                    },
                    "offers" : {
                        "@type" : "Offer",
                        "price" : frontmatter.price,
                        "priceCurrency": "USD",
                        "url" : env.domain + frontmatter.path
                    },
                    "description": frontmatter.description
                }}>
            <div className="container-fluid">
            <Row className={[productTemplateStyle.productContainer, "justify-content-center"].join(" ")} >
                <Col md={10} className={productTemplateStyle.productTemplateContent}>
                    <Row className={productTemplateStyle.productContent}>
                        <Col xs={12} md={6} sm={6} >
                            <Col className={productTemplateStyle.photoContent}>
                                <Img className={productTemplateStyle.frontPhoto} fluid={frontmatter.photo['childImageSharp']['fluid']} />
                                <Img className={productTemplateStyle.backPhoto} fluid={frontmatter.photoBack['childImageSharp']['fluid']} />
                            </Col>
                        </Col>
                        <Col xs={12} md={6} sm={6}>
                            <Row className="justify-content-left">
                                <Col>
                                    <p className={productTemplateStyle.productName}>{frontmatter.title} {frontmatter.density}</p>
                                </Col>
                            </Row>
                            <Row className="justify-content">
                                <Col>
                                    <p className={productTemplateStyle.aboutProduct}>{frontmatter.aboutProduct}</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-left">
                                <Col>
                                    <p className={productTemplateStyle.productDescription}>{frontmatter.description}</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-left">
                                <Col>
                                    <p className={productTemplateStyle.productPrice}>{frontmatter.price}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <span className={globalStyle.required}></span>
                                        <span className={globalStyle.required}></span>
                                        <span className={globalStyle.required}></span>
                                        Free shipping</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-left">
                                <Col>
                                    <span className={productTemplateStyle.productCompositionLabel}>Composition: </span>
                                    <p className={productTemplateStyle.productComposition}>{frontmatter.composition}</p>
                                </Col>
                            </Row>

                            <Form>
                                <Row className="justify-content-left">
                                    <Col md={4} sm={4} xs={12}>
                                        <Row>
                                            <Col>
                                            <Form.Group controlId="sizeForm">
                                                <Form.Label as="legend" md={6} sm={6} className="font-weight-bold">
                                                    <div>Select size: {formState.size}</div>
                                                </Form.Label>

                                                <Form.Control as="select" name="size" onChange={handleSizeSelection}>
                                                    <option value="">Select Size</option>
                                                    {frontmatter.sizes.map(size => (
                                                      <option key={size} value={size}>{size}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {
                                        frontmatter.title !== "MOM Racerback Tank Top" &&
                                        <Col md={8} sm={8} xs={12}>
                                            <Row className={[productTemplateStyle.sizeChart]}>
                                                <Col>
                                                    <p>Size Chart</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Img fluid={data.sizeChart.childImageSharp.fluid} />
                                                </Col>
                                            </Row>

                                        </Col>
                                    }
                                </Row>

                                {
                                    showSizeError &&
                                    <Row>
                                        <Col>
                                            <Alert className={"mt-2"} variant="danger" onClose={() => setShowSizeErrorAlert(false)} dismissible
                                            >Size is required!</Alert>
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col >
                                        <Form.Group  controlId="quantityForm" className={productTemplateStyle.quantityFormGroup} >
                                            <Form.Label as="legend" md={6} sm={6} className="font-weight-bold">
                                                <div>Quantity:</div>
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              name="quantity"
                                              min={1}
                                              max={100}
                                              value={formState.quantity}
                                              onChange={handleQuantityChange} >
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="formEmail">
                                        <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email</Form.Label>
                                        <Form.Control size="sm" value={formState.email} type="email" name="email" placeholder="Your email adress" onChange={handleFormChange} required/>
                                        {
                                            showEmailError &&
                                            <Alert className={"mt-2"} variant="danger" onClose={() => setShowEmailErrorAlert(false)} dismissible
                                            >Email is required</Alert>
                                        }
                                    </Form.Group>
                                </Form.Row>

                                <Row >
                                    <Col md={12} sm={12} xs={12}>
                                    <SkuCard title={frontmatter.title}
                                          quantity={formState.quantity}
                                          path={frontmatter.path}
                                          email={formState.email}
                                          size={formState.size}
                                          zipCode={formState.zipCode}
                                          state={formState.state}
                                          city={formState.city}
                                          addressLine1={formState.addressLine1}
                                          addressLine2={formState.addressLine2}
                                          firstName={formState.firstName}
                                          lastName={formState.lastName}
                                          price={frontmatter.price}
                                          linesId={frontmatter.linesId}
                                          productVariantId={frontmatter.productVariantId}
                                          productId={frontmatter.productId}
                                          sku={sku}
                                          beforeRedirect={validateForm}
                                    />
                                    </Col>
                                </Row>
                            </Form>

                            <Row>
                                <Col xs={12} sm={8}>
                                    {
                                        showAlert && formResult.result === 'error' &&
                                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible
                                        >Some error has occurred. Please send an email to <ContactEmailAddress/></Alert>
                                    }
                                    {
                                        showAlert && formResult.result === 'success' &&
                                        <Alert variant="primary" onClose={() => setShowAlert(false)} dismissible
                                        >We have recieved your email and will get back to you soon!</Alert>
                                    }
                                    {
                                        showError.show &&
                                        <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                                        >{showError.onField} field should not be empty</Alert>
                                    }

                                    {
                                        showError.show &&
                                        <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                                        >{showError} field should not be empty</Alert>
                                    }

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      rawMarkdownBody
      frontmatter {
        path
        title
        density
        posttype
        composition
        aboutProduct
        description
        price
        stoc
        sizes
        linesId
        productVariantId
        productId
        photo {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        photoBack {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    skus: allStripeSku {
      edges {
        node {
          id
          price
          attributes {
            size
          }
          product {
            id
            name
          }
        }
      }
    }
    sizeChart: file(relativePath: { eq: "market/product-related/size-chart.png" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        usaStates {
          name
          abbreviation
        }    
      }
    }
    logo: file(relativePath: { eq: "logo-new.jpg" }) {
        publicURL
    }
  }
`;
