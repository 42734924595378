import React from "react"
import { useStaticQuery, graphql } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const ContactEmailAddress = () => {
  const data = useStaticQuery(
    graphql`
      query ContactEmailAddressQuery {
        site(siteMetadata: {}) {
          siteMetadata {
            contactEmailAddress
          }
        }
      }
  `);

  return <a href={"mailto:" + data.site.siteMetadata.contactEmailAddress}>{data.site.siteMetadata.contactEmailAddress}</a>;
};

export default ContactEmailAddress
